
import { ApiBase } from "@/core/api";
import { defineComponent, onBeforeMount, ref } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  system_status: number | string;
  subject: string;
  type: string;
  apply_to: Array<any>;
}

export default defineComponent({
  name: "standard-operating-procedure-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      system_status: "",
      subject: "",
      type: "",
      apply_to: [],
    });
    const { t } = useI18n();
    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const typeOptions = ref([]);
    const typeLoading = ref<boolean>(false);
    const ouickInquiryApplyToOptions = ref([]);
    const callOutApplyToOptions = ref([]);
    const applyToLoading = ref<boolean>(false);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "call_out_subject",
          "quick_inquiry_comment_tagging",
          "distribution_task_type",
        ],
      });
      if (data.code == 0) {
        subjectOptions.value = data.data.call_out_subject.items;
        callOutApplyToOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        ouickInquiryApplyToOptions.value = data.data.call_out_subject.items;
        typeOptions.value = data.data.distribution_task_type.items;
      }
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const typeChange = () => {
      data.value.apply_to = [];
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });

    return {
      data,
      formRef,
      subjectOptions,
      subjectLoading,
      typeOptions,
      typeLoading,
      ouickInquiryApplyToOptions,
      callOutApplyToOptions,
      applyToLoading,
      t,
      typeChange,
      submit,
      handleReset,
    };
  },
});
